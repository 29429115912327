import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getGiftList,
  getVPSMachineTypeList,
  redeemGiftList,
} from "../../redux/action";
import { Store } from "../../redux/Actions";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import noImage from "../../assets/img/noImage.jpeg";
import { Card, Row, Col } from "react-bootstrap";
import { customFormatDatetimeWithLocale } from "../../util/utils";
import RedeemModal from "../../Components/Gift/RedeemModal";
import { FormattedMessage } from "react-intl";
import NoDatFound from "../../Components/common/NoDatFound";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import NoImage from "../../assets/img/image.png";
import DeletePopup from "../../Components/common/Popups/DeletePopup";
import moment from "moment";
import { ProgressDownload } from "../../Components/common/ProgressDownload";
import PermanantEARedeemModal from "../../Components/Gift/PermanantEARedeemModal";
import ReferralPermanentEaRedeemModel from "../../Components/Gift/ReferralPermanentEaRedeemModal";

export const Gifts = () => {
  const dispatch = useDispatch();
  const giftList =
    useSelector((state: Store) => state.giftReducer.giftLst) || [];
  const giftRedeemSuccess = useSelector(
    (state: Store) => state.giftReducer.giftRedeemSuccess,
  );
  const [modal, setModal] = useState(false);
  const [selGift, setSelGift] = useState<any>({});
  const [buttonClick, setButtonClick] = useState<any>(false);
  const [show, setShow] = useState({ id: "", modal: false, point: 0 });

  const isLoading = useSelector(
    (state: Store) => state.giftReducer.isLoading,
  ) as any;
  const onSaveLoading = useSelector(
    (state: Store) => state.giftReducer.onSaveLoading,
  ) as any;

  const success = useSelector(
    (state: Store) => state.giftReducer.success,
  ) as any;

  const language: string = useSelector(
    (state: Store) => state.userDataReducer.local,
  );

  const [Load, setLoad] = useState<any>([]);
  const btnRef = useRef<any>();

  useEffect(() => {
    dispatch(getVPSMachineTypeList());
    dispatch(getGiftList());
    setModal(false);
  }, []);

  useEffect(() => {
    const id = selGift?.id || 0;
    if (id > 0) {
      giftList.gift.map((i: any) => {
        if (id == i.id) {
          const exp = moment(moment(i.exipryDate).format("LL"))
            .toDate()
            .getTime();
          const today = moment(moment(new Date()).format("LL"))
            .toDate()
            .getTime();
          setSelGift({
            ...i,
            exp: i.redeemed ? false : !(exp >= today),
          });
        }
      });
    }
  }, [giftList]);

  useEffect(() => {
    if (!onSaveLoading && buttonClick) {
      setShow({
        id: "",
        modal: false,
        point: 0,
      });
    }
  }, [onSaveLoading, isLoading, buttonClick, giftList]);

  useEffect(() => {
    if (buttonClick && giftRedeemSuccess) {
      setModal(true);
      setButtonClick(false);
    }
  }, [giftRedeemSuccess]);

  const onCardClick = (selectedGift: any) => {
    if (selectedGift.exipryDate) {
      const exp = moment(moment(selectedGift.exipryDate).format("LL"))
        .toDate()
        .getTime();
      const today = moment(moment(new Date()).format("LL")).toDate().getTime();
      setSelGift({
        ...selectedGift,
        exp: selectedGift.redeemed ? false : !(exp >= today),
        giftCategoryId: selectedGift?.giftCategory?.id,
      });

      if (
        selectedGift.redeemed ||
        exp < today ||
        selectedGift.giftCategory.name === "VIP Free Course Code" ||
        selectedGift.giftCategory.name === "5000 Cash Coupon"
      ) {
        setModal(true);
        return;
      }

      setShow({
        id: "",
        modal: true,
        point: selectedGift.pointsRequired,
      });
    }
  };

  const onRedeem = (ea = {}) => {
    const redeemEaGiftId = [9, 15];
    if (redeemEaGiftId.includes(selGift.giftCategoryId)) {
      dispatch(redeemGiftList(true, selGift?.id, ea));
    } else {
      dispatch(redeemGiftList(true, selGift?.id));
    }
    setButtonClick(true);
    setShow((prv: any) => ({
      ...prv,
      modal: false,
      point: "",
    }));
  };

  const getImage = (isRedeemed: boolean, category: any) => {
    const exp = moment(moment(category.exipryDate).format("LL"))
      .toDate()
      .getTime();
    const today = moment(moment(new Date()).format("LL")).toDate().getTime();

    if (!isRedeemed && today <= exp) {
      return category?.giftCategory?.beforeImage || noImage;
    } else {
      if (isRedeemed) {
        return category?.giftCategory?.afterImage || noImage;
      } else {
        return category?.giftCategory?.expiredImage || noImage;
      }
    }
    return noImage;
  };

  const onDonwloadButtonClick = (id: any, url: any) => {
    if (!Load.includes(`${id}`)) {
      document.getElementById(`${id}1`)?.classList.remove("progress-hide");
      if (btnRef.current) {
        btnRef?.current.downloadFiles(url, `${id}`);
        setLoad((prv: any) => [...prv, `${id}`]);
      }
    }
  };

  return (
    <div className="content-wrapper">
      {isLoading && <CenterLoader />}

      <div className="content">
        <header className="page-header">
          <div className="d-flex align-items-center">
            <div className="mr-auto">
              <h1>
                {" "}
                <FormattedMessage id="gifts" defaultMessage="Gifts" />
              </h1>
            </div>
          </div>
        </header>
        <section className="page-content container-fluid">
          {giftList.gift && giftList.gift.length ? (
            <Row>
              {giftList.gift &&
                giftList.gift.length &&
                giftList.gift.map((i: any, index: any) => (
                  <>
                    {" "}
                    <Col xs={12} sm={6} md={6} lg={6} xl={4} className="m-b-30">
                      {i.giftCategory.id == 11 ? (
                        <ProgressDownload
                          setLoad={setLoad}
                          Load={Load}
                          elId={`p${index}1`}
                          ref={btnRef}
                        >
                          <Card
                            onClick={() => onCardClick(i)}
                            style={{ cursor: "pointer" }}
                            className="equal-height mb-0"
                          >
                            <CardContent>
                              <Typography className="text-center">
                                <h3 className="text-center">
                                  {i?.giftCategory?.name}
                                </h3>
                              </Typography>
                              <Typography className="text-center">
                                {i?.details}
                              </Typography>
                              <div className="gift-img my-3">
                                {i.giftCategory.show ? (
                                  <>
                                    <img
                                      className=" gift-image-before"
                                      src={getImage(i.redeemed, i)}
                                      style={{ height: "200px" }}
                                    />
                                  </>
                                ) : (
                                  <img
                                    className=" gift-image-expired"
                                    style={{ height: "200px" }}
                                    src={NoImage}
                                  />
                                )}
                              </div>
                              <Typography className="text-center">
                                {parseInt(i.pointsRequired) > 0 ? (
                                  <>
                                    {" "}
                                    <FormattedMessage
                                      id="PointsRequired"
                                      defaultMessage="Points Required"
                                    />{" "}
                                    : {i.pointsRequired}{" "}
                                  </>
                                ) : (
                                  <FormattedMessage
                                    id="free"
                                    defaultMessage="Free"
                                  />
                                )}
                              </Typography>
                              <Typography className="text-center">
                                {i.isRedeemed ? (
                                  <p>
                                    <FormattedMessage
                                      id="Redeemed"
                                      defaultMessage="Redeemed"
                                    />
                                  </p>
                                ) : (
                                  <p>
                                    <FormattedMessage
                                      id="redeemOnBefore"
                                      defaultMessage="Redeem on or before"
                                      values={{
                                        date: customFormatDatetimeWithLocale(
                                          i?.exipryDate,
                                          language,
                                        ),
                                      }}
                                    />{" "}
                                  </p>
                                )}
                              </Typography>
                            </CardContent>
                          </Card>
                        </ProgressDownload>
                      ) : (
                        <Card
                          onClick={() => onCardClick(i)}
                          style={{ cursor: "pointer" }}
                          className="equal-height mb-0"
                        >
                          <CardContent>
                            <Typography className="text-center">
                              <h3 className="text-center">
                                {i?.giftCategory?.name}
                              </h3>
                            </Typography>
                            <Typography className="text-center">
                              {i?.details}
                            </Typography>
                            <div className="gift-img my-3">
                              {i.giftCategory.show ? (
                                <>
                                  <img
                                    className=" gift-image-before"
                                    src={getImage(i.redeemed, i)}
                                    style={{ height: "200px" }}
                                  />
                                </>
                              ) : (
                                <img
                                  className=" gift-image-expired"
                                  style={{ height: "200px" }}
                                  src={NoImage}
                                />
                              )}
                            </div>
                            <Typography className="text-center">
                              {parseInt(i.pointsRequired) > 0 ? (
                                <>
                                  {" "}
                                  <FormattedMessage
                                    id="PointsRequired"
                                    defaultMessage="Points Required"
                                  />{" "}
                                  : {i.pointsRequired}{" "}
                                </>
                              ) : (
                                <FormattedMessage
                                  id="free"
                                  defaultMessage="Free"
                                />
                              )}
                            </Typography>

                            <Typography className="text-center">
                              {i.isRedeemed ? (
                                <p>
                                  <FormattedMessage
                                    id="Redeemed"
                                    defaultMessage="Redeemed"
                                  />
                                </p>
                              ) : (
                                <p>
                                  <FormattedMessage
                                    id="redeemOnBefore"
                                    defaultMessage="Redeem on or before"
                                    values={{
                                      date: customFormatDatetimeWithLocale(
                                        i?.exipryDate,
                                        language,
                                      ),
                                    }}
                                  />{" "}
                                </p>
                              )}
                            </Typography>
                          </CardContent>
                        </Card>
                      )}
                    </Col>
                  </>
                ))}
            </Row>
          ) : (
            <p>{!isLoading && <NoDatFound />}</p>
          )}

          {modal && (
            <RedeemModal
              details={selGift}
              onClose={() => setModal(false)}
              open={modal}
              onRedeem={() => onRedeem()}
              isLoading={false}
            />
          )}
          {show.modal && [12, 13, 14].includes(selGift.giftCategoryId) && (
            <RedeemModal
              details={selGift}
              onClose={() =>
                setShow((prv: any) => ({
                  ...prv,
                  modal: false,
                  point: "",
                }))
              }
              open={show.modal}
              isLoading={false}
            />
          )}

          {show.modal && [9].includes(selGift.giftCategoryId) && (
            <PermanantEARedeemModal
              // onClose={() => setModal(false)}
              onClose={() =>
                setShow((prv: any) => ({
                  ...prv,
                  modal: false,
                  point: "",
                }))
              }
              open={show.modal}
              isLoading={false}
              details={selGift}
              onRedeem={(ac: any) => onRedeem(ac)}
            />
          )}

          {show.modal && [15].includes(selGift.giftCategoryId) && (
            <ReferralPermanentEaRedeemModel
              onClose={() =>
                setShow((prv: any) => ({
                  ...prv,
                  modal: false,
                  point: "",
                }))
              }
              open={show.modal}
              isLoading={false}
              details={selGift}
              onRedeem={(ac: any) => onRedeem(ac)}
            />
          )}
          {show.modal &&
            ![9, 12, 13, 14, 15].includes(selGift.giftCategoryId) && (
              <DeletePopup
                message={
                  <FormattedMessage
                    id="Areyousurewanttoredeemgift?"
                    defaultMessage="Are you sure want to redeem gift ?"
                  />
                }
                onClose={() =>
                  setShow((prv: any) => ({
                    ...prv,
                    modal: false,
                    point: "",
                  }))
                }
                open={show.modal}
                onConfirm={() => onRedeem()}
                isLoading={onSaveLoading}
                isSubText={true}
                subText={
                  <FormattedMessage
                    id="msg.DeductPoints"
                    values={{ point: show.point }}
                    defaultMessage="Points Required"
                  />
                }
              />
            )}
        </section>
      </div>
    </div>
  );
};

export default Gifts;
