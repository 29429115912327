import { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Table } from "react-bootstrap";
import { Button } from "@mui/material";
import { Store } from "../../redux/Actions";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { ProgressDownload } from "../common/ProgressDownload";
import { DownloadsIcon } from "../../util/iconStyle";

const RedeemModal = ({ details, onClose, open }: any) => {
  const vpsTypeList = useSelector(
    (state: Store) => state.VPSmachineReducer.VPSMachineTypeList,
  );

  const [vpsTypeMapping, setVpsTypeMapping] = useState({});

  const handleClose = () => {
    onClose(false);
  };

  const [Load, setLoad] = useState<any>([]);
  const btnRef = useRef<any>();

  const onDonwloadButtonClick = (id: any, url: any) => {
    if (!Load.includes(`${id}`)) {
      document.getElementById(`${id}1`)?.classList.remove("progress-hide");
      if (btnRef.current) {
        btnRef?.current.downloadFiles(url, `${id}`);
        setLoad((prv: any) => [...prv, `${id}`]);
      }
    }
  };

  const showCode =
    details.giftCategory.name === "5000 Cash Coupon" ||
    details.giftCategory.name === "VIP Free Course Code";
  const showReasonOrPassword = details.giftCategory.name !== "5000 Cash Coupon";
  const showRedeemedBy =
    details.giftCategory.name === "VIP Free Course Code" && details.redeemed;

  useEffect(() => {
    if (vpsTypeList.length) {
      const mapping = {};
      for (const index in vpsTypeList) {
        mapping[vpsTypeList[index].id] = index;
      }
      setVpsTypeMapping(mapping);
    }
  }, [vpsTypeList]);

  return (
    <Dialog onClose={handleClose} open={open} fullWidth>
      {details && Object.keys(details).length > 0 ? (
        <>
          <DialogTitle>{details?.giftCategory?.name || "Gift"}</DialogTitle>
          <Table style={{ textAlign: "center" }}>
            <tr>
              <th>
                <FormattedMessage
                  id="gift.redeem.modal.head"
                  defaultMessage="Details"
                />
              </th>
              <td>{details.details}</td>
            </tr>

            {showCode ? (
              <tr>
                <th>
                  <FormattedMessage
                    id="gift.redeem.modal.code"
                    defaultMessage="Code"
                  />
                </th>

                <td>{details.code}</td>
              </tr>
            ) : null}

            {showReasonOrPassword ? (
              <tr>
                <th>
                  <FormattedMessage
                    id={
                      details.giftCategory.name === "VIP Free Course Code"
                        ? "gift.redeem.modal.password"
                        : "gift.redeem.modal.Reason"
                    }
                    defaultMessage="Reason"
                  />
                </th>
                <td>{details.reason}</td>
              </tr>
            ) : null}

            {showRedeemedBy ? (
              <tr>
                <th>
                  <FormattedMessage
                    id="gift.redeem.modal.redeemedBy"
                    defaultMessage="Redeemed By"
                  />
                </th>

                <td>{`${details.unRegisteredRedeemerName}`}</td>
              </tr>
            ) : null}

            {details?.code?.length > 0 &&
              !details.exp &&
              details.redeemed &&
              [1, 2].includes(details?.giftCategory?.id) && (
                <tr>
                  <th>
                    <FormattedMessage
                      id="gift.redeem.modal.GiftCode"
                      defaultMessage="Gift Code"
                    />
                  </th>
                  <td>{details.code}</td>
                </tr>
              )}
            {details?.code?.length > 0 &&
              [12, 13, 14].includes(details?.giftCategory?.id) && (
                <tr>
                  <th>
                    <FormattedMessage
                      id="gift.redeem.modal.vpsType"
                      defaultMessage="VPS Type"
                    />
                  </th>
                  <td>
                    {vpsTypeList[vpsTypeMapping[details.code]]?.typeName ||
                      "unknown"}
                  </td>
                </tr>
              )}
            {details?.giftAttachmentPath?.length > 0 &&
              !details.exp &&
              details.redeemed &&
              [11].includes(details?.giftCategory?.id) && (
                <tr>
                  <th>
                    <FormattedMessage
                      id="table.head.DownloadAttachment"
                      defaultMessage="Download"
                    />
                  </th>
                  <td>
                    <div style={{ textAlign: "center" }}>
                      <ProgressDownload
                        setLoad={setLoad}
                        Load={Load}
                        elId={`p${details?.id}1`}
                        ref={btnRef}
                      >
                        <button
                          className="btn btn-secondary btn-sm"
                          id={`p${details?.id}`}
                          onClick={() =>
                            onDonwloadButtonClick(
                              `p${details?.id}`,
                              details?.giftAttachmentPath,
                            )
                          }
                        >
                          <FormattedMessage
                            id="table.head.Download"
                            defaultMessage="Download"
                          />
                          <DownloadsIcon />
                        </button>
                      </ProgressDownload>
                    </div>
                  </td>
                </tr>
              )}
          </Table>
          <div style={{ textAlign: "end" }} className="mb-3">
            <Button
              size="small"
              variant="text"
              onClick={onClose}
              style={{ color: "#68439a" }}
              className="mr-3 ml-3"
            >
              <FormattedMessage id="page.Cancel" defaultMessage="Cancel" />
            </Button>
          </div>
        </>
      ) : null}
    </Dialog>
  );
};

RedeemModal.propTypes = {
  details: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onRedeem: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default RedeemModal;
